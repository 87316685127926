import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { getData } from './index';

import { history } from './../history';

// types
import { USER_CONNECTED, USER_LOGOUT, USER_GETSELF, USER_ERROR } from './types';

export const setConnected = createAction<boolean>(USER_CONNECTED);

export const loginUser = (login: string, password: string) => (dispatch: any) => {
    //dispatch({ type: LOADING_UI })

    let userData = {
        login,
        password
    }
    axios.post(process.env.REACT_APP_API_URL + "/auth/login", userData).then((res) => {
        //console.log("login data", res.data);
		localStorage.setItem('userAdminToken', res.data.token);
		dispatch(setConnected(true))
		dispatch(getUserSelf());
        history.push("/")


    }).catch((err) => {
        console.log(err);
        /*dispatch({
            type: SET_ERRORS,
            payload: err.response.data
        });*/
    });
}

export const logoutUser = () => (dispatch: any) => {
	dispatch({type:USER_LOGOUT})
}

export const getUserSelf = () => (dispatch: any) => {
	const url = "/user/self";
	getData(USER_GETSELF, USER_ERROR, true, url, dispatch);
}

export const getUserData = () => (dispatch: any) => {
    //dispatch({ type: LOADING_USER });
    axios.get("/user").then(res => {
        console.log("user data", res.data);
        /*dispatch({
            type: SET_USER,
            payload: res.data
        });*/
    }).catch(err => {
        console.log(err);
    });
}