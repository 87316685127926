import React from "react";
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from "react-router-dom";
import { history } from "./history";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { Main } from "./components/Main/Main";
import { Login } from "./components/Login/Login";
import { Manager } from "./components/Manager/Manager";
import { Sessions } from "./components/Sessions/Sessions";
import { Session } from "./components/Sessions/Session";
import { Experiences } from "./components/Experience/Experiences";
import { Experience } from "./components/Experience/Experience";
import { useSelector,useDispatch } from "react-redux";
import { IRootState } from "./reducers";
import { Header } from './components/Partials/Header';
import { Footer } from './components/Partials/Footer';
import { Popup } from './components/Partials/Popup';
import { setPopupDisplay } from "./actions/popup";

function App() {
	let user = useSelector((state: IRootState) => state.user);
	let popup = useSelector((state: IRootState) => state.popup);
	const dispatch = useDispatch();
	dispatch(setPopupDisplay(true));
	console.log("popup = ", popup);
	return (
		<HistoryRouter history={history}>
			{/* {popup.display && <Popup></Popup>} */}
			<Header />
			
			<Routes>
				{user.connected && <Route path='/' element={<Main />} />}
				<Route path='/login' element={<Login />} />
				{user.connected && <Route path='/manager' element={<Manager />} />}
				{user.connected && <Route path='/sessions' element={<Sessions />} />}
				{user.connected && <Route path='/session/:id' element={<Session />} />}
				{user.connected && <Route path='/experiences' element={<Experiences />} />}
				{user.connected && <Route path='/experience/:id' element={<Experience />} />}
				<Route path='*' element={<Login />} />
			</Routes>
			<Footer />
		</HistoryRouter>
	);
}

export default App;
