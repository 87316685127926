import React, { useState, useEffect } from "react";
import axios from "axios";
import "./touchscreen.css";
import Popup from "../Partials/Popup";
import { useDispatch, useSelector } from "react-redux";
import { setPopupDisplay } from "../../actions/popup";
import { IRootState } from "../../reducers";

const TouchscreenComponent = ({ clientId, socket }: { clientId: string; socket: any }) => {
	const [showPopup, setShowPopup] = useState(false);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setPopupDisplay(true));
	}, [dispatch]);

	const handleActivateTouch = () => {
		console.log(`Activation du tactile pour le client ${clientId}`);
		socket.emit("onTouchEnable", clientId);
		setShowPopup(false);
	};

	const handleDeactivateTouch = () => {
		console.log(`Désactivation du tactile pour le client ${clientId}`);
		socket.emit("onTouchDisable", clientId);
		setShowPopup(false);
	};
	const handleClosePopup = () => {
		setShowPopup(false);
	};

	return (
		<>
			<span className='touchscreen' onClick={() => setShowPopup(true)}>
				{" "}
				Touchscreen{" "}
			</span>
			{showPopup ? (
				<Popup
					title={"Touchscreen"}
					body={"Do you want to enable or disable the touchscreen ?"}
					primaryLabel={"Enable touchscreen"}
					secondaryLabel={"Disable touchscreen"}
					onPrimaryClick={handleActivateTouch}
					onSecondaryClick={handleDeactivateTouch}
					handleClose={handleClosePopup}
					show={showPopup}
				/>
			) : null}
		</>
	);
};

export default TouchscreenComponent;
