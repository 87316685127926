// React
import React, { useEffect, useState, useRef } from "react";
// Redux
/*import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'*/
import { io, Socket } from "socket.io-client";
import axios from "axios";
// Components
import { Header } from "./../Partials/Header";
import { Footer } from "./../Partials/Footer";
// Style
import { Container, Button, Card, Row, Col, Table } from "react-bootstrap";
// Css
import "./sessions.css";
import payedImg from "../../style/assets/argent.png";
import socialMediasImg from "../../style/assets/socialMedias.png";
import langImg from "../../style/assets/lang.png";
import galerieImg from "../../style/assets/galerie.png";
import gifImg from "../../style/assets/gif.png";
import moment from "moment";
import { getDataSimple, postDataSimple } from "../../actions";

const download = require("downloadjs");

export const API_URL = process.env.REACT_APP_API_URL;

const FiltersBox = (props: any) => {
	return (
		<div className={"filtersBox"}>
			<div className='filterBoxPart filterBox-experienceChoice'>
				<div className='filterBox-label'>Experience :</div>
				<select
					onChange={(e) => props.setIdExperience(e.target.value)}
					name='idExperience'
					id='idExperience'
					value={props.idExperience}
				>
					<option value=''> -- Sélectionnez -- </option>
					{props.experiences.length > 0 &&
						props.experiences.map((exp: any) => {
							return (
								<option key={exp._id} value={exp._id}>
									{exp.experienceName}
								</option>
							);
						})}
				</select>
			</div>

			<div className='filterBoxPart filterBox-mail'>
				<div className='filterBox-label'>Mail :</div>
				<input
					id='mail-searching'
					type='text'
					placeholder={"recherche@mail.com"}
					value={props.mailSearch}
					onChange={(e) => {
						let current =
							(document.getElementById("mail-searching") as HTMLInputElement)
								?.value || "";
						props.setMailSearch(current);
					}}
				></input>
			</div>

			<div className='filterBoxPart filterBox-dateAfter'>
				<div className='filterBox-label'>Entre (inclus) :</div>
				<input
					onChange={(e) => {
						props.setAfterDate(e.target.value);
					}}
					type='datetime-local'
					id='after-time'
					name='after-time'
					value={props.afterDate}
				/>
			</div>
			<div className='filterBoxPart filterBox-dateBefore'>
				<div className='filterBox-label'>et :</div>
				<input
					onChange={(e) => {
						props.setBeforeDate(e.target.value);
					}}
					type='datetime-local'
					id='before-time'
					name='before-time'
					value={props.beforeDate}
				/>
			</div>

			<div className='filterBoxPart filterBox-authorizationSharing'>
				<div className='filterBox-label'>Autor. partage :</div>
				<select
					onChange={(e) => props.setAuthorizationAllow(e.target.value)}
					name='authorizationAllow'
					id='authorizationAllow'
					value={props.authorizationAllow}
				>
					<option selected> -- Sélectionnez -- </option>
					<option value='true'>✔</option>
					<option value='false'>❌</option>
				</select>
			</div>

			<div className='filterBoxPart filterBox-buttons'>
				<button
					className='main-firstline-sessionchoice-button filterBox-button'
					onClick={async () => {
						props.setPage(-1);
					}}
				>
					Update!
				</button>
				<button
					className='main-firstline-sessionchoice-button filterBox-button'
					onClick={props.resetFilters}
				>
					Reset Filters
				</button>
			</div>
		</div>
	);
};

const TakeCard = (props: any) => {
	let take = props.take;
	let session = props.session;
	//console.log("take card session id experience: ", props.experiences);

	//console.log("props.experiences", props.experiences);
	return (
		<div
			style={{
				width: "32%",
				padding: "0",
				margin: "0",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div>
				{take +
					(session.order?.find((t: any) => t._id === take).ordered
						? " [Buyed]"
						: "")}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<a href={API_URL + "/data/mini/" + take + ""} target={"_blank"}>
					<img width={100} src={API_URL + "/data/mini/" + take + ""} />
				</a>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<a
					href={API_URL + "/data/print/" + session._id + "/" + take + ""}
					target={"_blank"}
				>
					Full resolution
				</a>
			</div>
			<div>
				<a
					href={API_URL + "/data/video/" + session._id + "/" + take + ""}
					target={"_blank"}
				>
					Video
				</a>
			</div>
			<div
				style={{
					margin: "8px 0 8px 0",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{"User's Share Links : "}

				{/* /****
				
				
				{experiences && experiences.find((e: any) => e.experienceName === session.idExperience) && (
										<a
											style={{
												margin: "0 0 0 0",
												display: "flex",
												flexDirection: "column",
												justifyContent: "center",
												alignItems: "center",
												textAlign: "center",
											}}
											href={
												experiences.find((e: any) => e.experienceName === session.idExperience)
													.urlFront +
												"/p/" +
												session._id
											}
											target={"_blank"}
										>
											{experiences.find((e: any) => e.experienceName === session.idExperience)
												.urlFront +
												"/p/" +
												session._id}
										</a>
									)}
									
									
									 */}
				<div
					style={{
						height: "24px",
						width: "24px",
						margin: "0 8px 0 4px",
						backgroundImage: `url(${galerieImg})`,
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}
					onClick={() => {
						let text =
							props.experiences.find(
								(e: any) => e._id === session.idExperience._id
							).urlFront +
							"/p/" +
							take;
						navigator.clipboard.writeText(text).then(
							function () {
								alert("The photo link has been copied to the clipboard.");
							},
							function () {
								/* l'écriture dans le presse-papier a échoué */
							}
						);
					}}
				></div>
				<div
					style={{
						height: "24px",
						width: "24px",
						margin: "0 4px 0 4px",
						backgroundImage: `url(${gifImg})`,
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}
					onClick={() => {
						let text =
							props.experiences.find((e: any) => {
								console.log("==>", e._id, session.idExperience._id);
								return e._id === session.idExperience._id;
							}).urlFront +
							"/v/" +
							take;
						navigator.clipboard.writeText(text).then(
							function () {
								alert("The video link has been copied to the clipboard.");
							},
							function () {
								/* l'écriture dans le presse-papier a échoué */
							}
						);
					}}
				></div>
			</div>
		</div>
	);
};

const SessionLine = (props: any) => {
	let session = props.session;

	const [deployed, setDeployed] = useState(false);

	return (
		<tr
			className='table-secondary'
			style={{ width: "100%", borderBottom: "1px solid #ADADAD" }}
		>
			<td
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					width: "100%",
					height: "100%",
				}}
			>
				<Row
					style={{
						width: "100%",
						display: "flex",
						cursor: "pointer",
						margin: "0 0 4px 0",
					}}
				>
					<Col
						xs={7}
						md={4}
						onClick={() => {
							document.location.href = "/session/" + session._id;
						}}
					>
						{moment(session.createdAt).format("DD/MM/YYYY HH:mm:ss") || "-"}
						<br />
						{session._id + " ↵"}
					</Col>
					<Col
						xs={4}
						md={4}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						<div>{session.idExperience?.experienceName || "N/A"}</div>
					</Col>
					<Col
						className='d-none d-md-flex'
						md={3}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					></Col>
					<Col
						xs={1}
						md={1}
						style={{
							padding: "0",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
							textAlign: "right",
						}}
						onClick={() => setDeployed(!deployed)}
					>
						<div
							style={{
								paddingBottom: "4px",
								backgroundColor: "lightgrey",
								aspectRatio: "1/1",
								height: "1.2em",
								transformOrigin: "center center",
								transform: "rotateZ(90deg)",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{">"}
						</div>
					</Col>
				</Row>
				<Row
					style={{
						width: "calc(100% + 24px)",
						display: !deployed ? "none" : "flex",
						cursor: "pointer",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#F9F9F9",
					}}
				>
					<h5 style={{ margin: "8px 0 0 0" }}></h5>
					{session.takes.map((take: any, index: number) => {
						return (
							<TakeCard
								key={"takecard" + index}
								experiences={props.experiences}
								session={session}
								take={take}
							/>
						);
					})}
				</Row>
				<Row
					style={{
						width: "100%",
						display: "flex",
						cursor: "pointer",
						margin: deployed ? "8px 0 0 0" : "0",
					}}
				>
					<Col
						xs={4}
						md={3}
						className='justify-content-center justify-content-md-start'
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<div
							style={{
								height: "24px",
								width: "24px",
								backgroundImage: `url(${payedImg})`,
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
							}}
						></div>
						<div style={{ margin: "0 0 0 8px" }}>
							{session.paiement === "ok"
								? session.order?.filter((o: any) => o.ordered).length +
								  "/" +
								  session.order?.length
								: session.paiement}
						</div>
					</Col>
					<Col
						xs={4}
						md={3}
						className='justify-content-center justify-content-md-start'
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<div
							style={{
								height: "24px",
								width: "24px",
								backgroundImage: `url(${langImg})`,
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
							}}
						></div>
						<div style={{ margin: "0 0 0 8px" }}>{session.lang || "N/A"}</div>
					</Col>
					<Col
						xs={4}
						md={3}
						className='justify-content-center justify-content-md-start'
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<div
							style={{
								height: "24px",
								width: "24px",
								backgroundImage: `url(${socialMediasImg})`,
								backgroundPosition: "center",
								backgroundRepeat: "no-repeat",
								backgroundSize: "90%",
							}}
						></div>
						<div style={{ margin: "0 0 0 8px" }}>
							{session.authorizationSharing ? "Yes" : "No"}
						</div>
					</Col>
				</Row>
			</td>
		</tr>
	);
};

export const Sessions = () => {
	const [sessions, setSessions] = useState<any>([]);
	const [sessionsCount, setSessionsCount] = useState<number>(0);
	const [page, setPage] = useState<number>(0);
	const [experiences, setExperiences] = useState<any>([]);
	const [experiencesCounter, setExperiencesCounter] = useState<any>([]);

	useEffect(() => {
		let urlExperience = "/tscapture/experiences";

		getDataSimple("getExperiencesError", true, urlExperience)
			.then((response) => {
				setExperiences(response.response.data.experiences);
			})
			.catch((e: any) => {});

		// axios({
		// 	url: urlExperience,
		// 	method: "GET",
		// })
		// 	.then((response) => {
		// 		console.log("experiencesRetrieved=", response);
		// 		setExperiences(response.data.experiences);
		// 	})
		// 	.catch((e: any) => {});

		let urlExperienceCounter = "/tscapture/experiencescounter";
		getDataSimple("getExperiencesCounterError", true, urlExperienceCounter)
			.then((response) => {
				setExperiencesCounter(response.response.data.experiencesCounter);
			})
			.catch((e: any) => {});

		// axios({
		// 	url: urlExperienceCounter,
		// 	method: "GET",
		// })
		// 	.then((response) => {
		// 		console.log("experiencesCounterRetrieved=", response);
		// 		setExperiencesCounter(response.data.experiencesCounter);
		// 	})
		// 	.catch((e: any) => {});
	}, []);

	useEffect(() => {
		if (page < 0) {
			setPage(0);
			return;
		}

		let urlSessions = "/session/all";

		let idExperience = (document.getElementById("idExperience") as any)?.value;
		let authorizationAllow = (
			document.getElementById("authorizationAllow") as any
		)?.value;

		postDataSimple("getSessionError", true, urlSessions, {
			skip: page * 20,
			limit: 20,
			mailSearch: mailSearch,
			before: beforeDate,
			after: afterDate,
			idExperience: idExperience,
			authorization:
				authorizationAllow === "false"
					? false
					: authorizationAllow === "true"
					? true
					: undefined,
		})
			.then((response) => {
				setSessions(response.response.data.sessions);
				setSessionsCount(response.response.data.sessionsCount);
			})
			.catch((e: any) => {
				console.log("response data sessions = ", e);
			});
		// if (isAuthReq) {
		// 	headers = { headers: { Authorization: localStorage['userAdminToken'] } };
		// }
		// axios({
		// 	url: urlSessions,
		// 	method: "POST",
		// 	headers: { Authorization: localStorage['userAdminToken'] },
		// 	data: {
		// 		skip: page * 20,
		// 		limit: 20,
		// 		mailSearch: mailSearch,
		// 		before: beforeDate,
		// 		after: afterDate,
		// 		idExperience: idExperience,
		// 		authorization:
		// 			authorizationAllow === "false" ? false : authorizationAllow === "true" ? true : undefined,
		// 	},
		// }).then((response) => {
		// 	setSessions(response.data.sessions);
		// 	setSessionsCount(response.data.sessionsCount);
		// 	console.log("sessions = ", response.data.sessions);
		// });
	}, [page]);

	const [beforeDate, setBeforeDate] = useState("");
	const [afterDate, setAfterDate] = useState("");
	const [mailSearch, setMailSearch] = useState("");
	const [idExperience, setIdExperience] = useState("");
	const [authorizationAllow, setAuthorizationAllow] = useState("");

	const [filtersPopup, displayFiltersPopup] = useState(false);

	useEffect(() => {
		let beforeInput = document.getElementById("before-time");

		if (beforeInput && beforeDate === "") {
			const tmp = new Date();
			const tmpyear = tmp.getFullYear();
			const tmpmonth = String(tmp.getMonth() + 1).padStart(2, "0");
			const tmpday = String(tmp.getDate() + 1).padStart(2, "0");
			const formattedDate = `${tmpyear}-${tmpmonth}-${tmpday}T00:00`;

			setBeforeDate(formattedDate);
		}

		let afterInput = document.getElementById("after-time");

		if (afterInput && afterDate === "") {
			const tmp = new Date();
			tmp.setDate(tmp.getDate() - 30);
			const tmpyear = tmp.getFullYear();
			const tmpmonth = String(tmp.getMonth() + 1).padStart(2, "0");
			const tmpday = String(tmp.getDate() + 1).padStart(2, "0");
			const formattedDate = `${tmpyear}-${tmpmonth}-${tmpday}T00:00`;

			setAfterDate(formattedDate);
		}
	}, [filtersPopup]);

	// const _beforeDate = useRef(beforeDate);
	// const _afterDate = useRef(afterDate);
	// const _mailSearch = useRef(mailSearch);
	// const _idExperience = useRef(idExperience);
	// const _authorizationAllow = useRef(authorizationAllow);

	useEffect(() => {
		console.log("mailSearch = ", mailSearch);
	}, [mailSearch]);

	useEffect(() => {
		const savedFilters = sessionStorage.getItem("sessionsFilters");
		if (savedFilters) {
			const filters = JSON.parse(savedFilters);
			setBeforeDate(filters.beforeDate);
			setAfterDate(filters.afterDate);
			setMailSearch(filters.mailSearch);
			setIdExperience(filters.idExperience);
			setAuthorizationAllow(filters.authorizationAllow);
		}
	}, []);

	useEffect(() => {
		const filters = {
			beforeDate,
			afterDate,
			mailSearch,
			idExperience,
			authorizationAllow,
		};
		sessionStorage.setItem("sessionsFilters", JSON.stringify(filters));

		// _beforeDate.current = beforeDate;
		// _afterDate.current = afterDate;
		// _mailSearch.current = mailSearch;
		// _idExperience.current = idExperience;
		// _authorizationAllow.current = authorizationAllow;
	}, [beforeDate, afterDate, mailSearch, idExperience, authorizationAllow]);

	const resetFilters = () => {
		setBeforeDate("");
		setAfterDate("");
		setMailSearch("");
		setIdExperience("");
		setAuthorizationAllow("");
		sessionStorage.removeItem("sessionsFilters");
	};

	return (
		<Container className='main'>
			<div className='main-firstline'>
				<div className='main-firstline-title'>
					<h3>{"Sessions :"}</h3>
				</div>
				<div className='main-firstline-sessionchoice'>
					<button
						id='downloadButton'
						className='main-firstline-sessionchoice-button'
						onClick={async (e: any) => {
							let sessionsLength = await axios({
								url: API_URL + "/take/getauthorizedlength",
								method: "POST",
								data: {
									before: beforeDate,
									after: afterDate,
									idExperience: idExperience,
									authorization:
										authorizationAllow === "false"
											? false
											: authorizationAllow === "true"
											? true
											: undefined,
								},
							});

							if (sessionsLength) {
								if (sessionsLength.data.count < 200) {
									if (
										window.confirm(
											"Êtes-vous sûr de vouloir télécharger " +
												sessionsLength.data.count +
												" sessions ?"
										)
									) {
										(e.target as HTMLElement).innerHTML = "Downloading 0%";

										(e.target as HTMLElement).style.pointerEvents = "none";
										(e.target as HTMLElement).style.cursor = "not-allowed";

										let idExperience = (
											document.getElementById("idExperience") as any
										)?.value;
										let authorizationAllow = (
											document.getElementById("authorizationAllow") as any
										)?.value;

										axios({
											url: API_URL + "/take/getauthorized",
											method: "POST",
											data: {
												// skip: page * 20,
												// limit: 20,
												before: beforeDate,
												after: afterDate,
												idExperience: idExperience,
												authorization:
													authorizationAllow === "false"
														? false
														: authorizationAllow === "true"
														? true
														: undefined,
											},
											responseType: "blob",
											onDownloadProgress: (progressEvent: any) => {
												var percentCompleted = Math.round(
													(progressEvent.loaded * 100) / progressEvent.total
												);

												(e.target as HTMLElement).innerHTML =
													"Downloading " + percentCompleted + "%";
											},
										}).then((response) => {
											const type = response.headers["content-type"];
											const blob = new Blob([response.data], {
												type: type,
											});
											const link = document.createElement("a");
											link.href = window.URL.createObjectURL(blob);
											link.download =
												"TSCapture_AuthorizedPrints_" +
												idExperience +
												"_" +
												beforeDate +
												"_" +
												afterDate +
												".zip";
											link.click();
											URL.revokeObjectURL(link.href);

											(e.target as HTMLElement).innerHTML = "Download!";
											(e.target as HTMLElement).style.pointerEvents = "all";
											(e.target as HTMLElement).style.cursor = "pointer";
										});
									}
								} else {
									window.alert(
										"Votre requête comporte plus de 200 sessions (" +
											sessionsLength.data.count +
											") : téléchargement impossible."
									);
								}
							}

							/*if (
								window.confirm(
									"Êtes-vous sûr que le nombre de sessions est faible ?"
								)
							) {
								(e.target as HTMLElement).innerHTML = "Downloading 0%";

								(e.target as HTMLElement).style.pointerEvents = "none";
								(e.target as HTMLElement).style.cursor = "not-allowed";

								let idExperience = (
									document.getElementById("idExperience") as any
								)?.value;
								let authorizationAllow = (
									document.getElementById("authorizationAllow") as any
								)?.value;

								axios({
									url: API_URL + "/take/getauthorized",
									method: "POST",
									data: {
										// skip: page * 20,
										// limit: 20,
										before: beforeDate,
										after: afterDate,
										idExperience: idExperience,
										authorization:
											authorizationAllow === "false"
												? false
												: authorizationAllow === "true"
												? true
												: undefined,
									},
									responseType: "blob",
									onDownloadProgress: (progressEvent: any) => {
										var percentCompleted = Math.round(
											(progressEvent.loaded * 100) / progressEvent.total
										);

										(e.target as HTMLElement).innerHTML =
											"Downloading " + percentCompleted + "%";
									},
								}).then((response) => {
									const type = response.headers["content-type"];
									const blob = new Blob([response.data], {
										type: type,
									});
									const link = document.createElement("a");
									link.href = window.URL.createObjectURL(blob);
									link.download =
										"TSCapture_AuthorizedPrints_" +
										idExperience +
										"_" +
										beforeDate +
										"_" +
										afterDate +
										".zip";
									link.click();
									URL.revokeObjectURL(link.href);

									(e.target as HTMLElement).innerHTML = "Download!";
									(e.target as HTMLElement).style.pointerEvents = "all";
									(e.target as HTMLElement).style.cursor = "pointer";
								});
							}*/
						}}
					>
						Download!
					</button>
					<div className='filtersBoxButton'>
						<div
							style={{
								width: "100%",
								height: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={() => {
								if (filtersPopup) {
									displayFiltersPopup(false);
								} else {
									displayFiltersPopup(true);
								}
							}}
						>
							Filters
						</div>
						{filtersPopup && (
							<FiltersBox
								experiences={experiences}
								idExperience={idExperience}
								setIdExperience={setIdExperience}
								mailSearch={mailSearch}
								setMailSearch={setMailSearch}
								afterDate={afterDate}
								setAfterDate={setAfterDate}
								beforeDate={beforeDate}
								setBeforeDate={setBeforeDate}
								authorizationAllow={authorizationAllow}
								setAuthorizationAllow={setAuthorizationAllow}
								setPage={setPage}
								resetFilters={resetFilters}
							/>
						)}
					</div>
				</div>
			</div>

			<table className='table' style={{ width: "100%" }}>
				<tbody style={{ width: "100%" }}>
					{sessions.map((s: any, index: number) => {
						return (
							<SessionLine
								key={"sessionLine" + index}
								experiences={experiences}
								session={s}
							/>
						);
					})}
				</tbody>
			</table>
			<div className='pagination'>
				{page > 0 && (
					<div className='arrowLeft' onClick={() => setPage(page - 1)}>
						{"<"}
					</div>
				)}
				<div className='pagination-numbers'>
					{[...Array(Math.floor(sessionsCount / 20) + 1)].map((value, i) => {
						if (i > page - 5 && i < page + 5) {
							return (
								<div
									key={"pagination" + i}
									className='pagination-number'
									style={{
										color: i === page ? "red" : "black",
										fontWeight: i === page ? "bold" : "normal",
									}}
									onClick={() => setPage(i)}
								>
									{i + 1}
								</div>
							);
						}
						if (page > 4) {
							if (i === 0) {
								return (
									<div
										key={"pagination" + i}
										className='pagination-number'
										style={{
											color: i === page ? "red" : "black",
											fontWeight: i === page ? "bold" : "normal",
										}}
										onClick={() => setPage(i)}
									>
										{i + 1}
									</div>
								);
							} else if (i === 1) {
								return (
									<div
										key={"pagination" + i}
										className='pagination-number'
										style={{
											color: i === page ? "red" : "black",
											fontWeight: i === page ? "bold" : "normal",
										}}
									>
										{"..."}
									</div>
								);
							}
						}
						if (page < Math.floor(sessionsCount / 20) + 1) {
							if (i === Math.floor(sessionsCount / 20)) {
								return (
									<div
										key={"pagination" + i}
										className='pagination-number'
										style={{
											color: i === page ? "red" : "black",
											fontWeight: i === page ? "bold" : "normal",
										}}
										onClick={() => setPage(i)}
									>
										{i + 1}
									</div>
								);
							} else if (i === Math.floor(sessionsCount / 20) - 1) {
								return (
									<div
										key={"pagination" + i}
										className='pagination-number'
										style={{
											color: i === page ? "red" : "black",
											fontWeight: i === page ? "bold" : "normal",
										}}
									>
										{"..."}
									</div>
								);
							}
						}
					})}
				</div>
				{page < Math.floor(sessionsCount / 20) && (
					<div className='arrowRight' onClick={() => setPage(page + 1)}>
						{">"}
					</div>
				)}
			</div>
			<h3>{"Stats :"}</h3>
			{experiencesCounter &&
				Object.entries(experiencesCounter).map((e: any, index: number) => {
					return (
						<div
							key={"experiencesCounter" + index}
							style={{ width: "100%", border: "1px solid black" }}
						>
							<h5>
								{e[0] +
									" - " +
									e[1].totalUses +
									" uses (" +
									e[1].paiementOk +
									"✓ / " +
									e[1].paiementKo +
									"✕) :"}
							</h5>
							{Object.entries(e[1].counters).map((w: any, index: number) => {
								return <div key={"counter" + index}>{w[0] + " - " + w[1]}</div>;
							})}
						</div>
					);
				})}
		</Container>
	);
};
