// React
import React, { useEffect, useState } from 'react';
// Redux
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'
// Actions
import { getUserSelf, logoutUser } from './../../actions/user';
// Components

// Style
import { Container, Nav, Navbar, /*NavDropdown*/ } from 'react-bootstrap';


export const Header = (props:any) => {

    const navigate = useNavigate();
	const location = useLocation();

    const { connected, login } = useSelector((state: any) => state.user);
    const [expanded, setExpanded] = useState(false);

	const dispatch: Dispatch<any> = useDispatch();

	useEffect(() => {
		dispatch(getUserSelf());
	}, [dispatch]);

	useEffect(() => {
		if(connected && location.pathname === "/login"){
			navigate('/')
		}
	}, [connected, location.pathname, navigate]);

    const navigateAndCloseMenu = (path: string) => {
        navigate(path);
        setExpanded(false);
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" expanded={expanded}>
            <Container>
                <Navbar.Brand onClick={() => navigateAndCloseMenu('/') }>Timescope Capture Administration</Navbar.Brand>
                
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(prevExpanded => !prevExpanded)} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
						{connected ? 
                            <React.Fragment>
                                <Nav.Link onClick={() => navigateAndCloseMenu('/manager')}>Manager</Nav.Link>
                                <Nav.Link onClick={() => navigateAndCloseMenu('/sessions')}>Sessions</Nav.Link>
                                <Nav.Link onClick={() => navigateAndCloseMenu('/experiences')}>Experiences</Nav.Link>
                            </React.Fragment>
						: null}
                        {/*<Nav.Link href="#features">Features</Nav.Link>
                        <Nav.Link href="#pricing">Pricing</Nav.Link>
                        <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown>*/}
                    </Nav>
                    <Nav>
						{connected ? 
							<React.Fragment>
								<Nav.Link>Hello {login}</Nav.Link>
								<Nav.Link onClick={() => dispatch(logoutUser()) }>Sign Out</Nav.Link>
							</React.Fragment>
							: 
							<React.Fragment>
                                <Nav.Link onClick={() => navigateAndCloseMenu('/login') }>Login</Nav.Link>
							</React.Fragment>
						}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}