import axios from "axios";

// Actions
import { logoutUser } from "./../actions/user";

// URL list
export const API_URL = process.env.REACT_APP_API_URL;

// Get Request
export function getData(action: string, errorType: string, isAuthReq: boolean, url: string, dispatch: Function) {
	const requestUrl = API_URL + url;
	let headers = {};

	if (isAuthReq) {
		headers = { headers: { Authorization: localStorage["userAdminToken"] } };
	}

	return axios
		.get(requestUrl, headers)
		.then((response) => {
			dispatch({
				type: action,
				payload: response.data,
			});
			return { response };
		})
		.catch((error) => {
			return errorHandler(dispatch, error, errorType);
		});
}

// Get Request Simple
export function getDataSimple(errorType: string, isAuthReq: boolean, url: string) {
	const requestUrl = API_URL + url;
	let headers = {};

	if (isAuthReq) {
		headers = { headers: { Authorization: localStorage["userAdminToken"] } };
	}

	return axios
		.get(requestUrl, headers)
		.then((response) => {
			return { response };
		})
		.catch((error) => {
			return errorHandler(null, error, errorType);
		});
}

export function postDataSimple(errorType: string, isAuthReq: boolean, url: string, data: any) {
	const requestUrl = API_URL + url;
	let headers = {};

	if (isAuthReq) {
		headers = { headers: { Authorization: localStorage["userAdminToken"] } };
	}

	return axios
		.post(requestUrl, data, headers)
		.then((response) => {
			return { response };
		})
		.catch((error) => {
			return errorHandler(null, error, errorType);
		});
}

export function errorHandler(dispatch: Function | null, error: any, type: string, requestData?: any) {
	console.error("Error type: ", type);
	console.error(error);

	// NOT AUTHENTICATED ERROR
	if ((error.status && error.status === 401) || (error.response && error.response.status === 401)) {
		if (dispatch) {
			return dispatch(logoutUser());
		}
		//console.log("not authenticated")
	}

	if (dispatch) {
		dispatch({
			type,
			payload: error && error.response ? error.response.data : error || "unknown error",
			requestData, // put request data in response so reducers know what is it about
		});
	}

	return { error };
}
