// React
import React, { useEffect, useState, useRef } from "react";
// Redux
/*import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'*/
import { useNavigate, useLocation } from 'react-router-dom'
import axios from "axios";
// Components
import { Header } from "./../Partials/Header";
import { Footer } from "./../Partials/Footer";
// Actions
import { getDataSimple } from "./../../actions"
// Style
import { Container, Button, Card, Row, Col, Table } from "react-bootstrap";
// Css
//import "./manager.css";

export const Experiences = () => {

	const navigate = useNavigate();

	const [experiences, setExperiences] = useState<any>([]);

	useEffect(() => {
		let urlExperience = "/tscapture/experiences";

		getDataSimple("getExperiencesError", true, urlExperience).then(response => {
			setExperiences(response.response.data.experiences);
		}).catch((e: any) => { });
		
	}, []);

	return (
		<Container className='main'>
			<h3>Devices :</h3>

			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Id</th>
					</tr>
				</thead>
				<tbody>
					{experiences && experiences.map((d: any, index: number) => {
						return (
							<tr key={"experience"+d._id}>
								<td><a style={{ cursor:"pointer" }} onClick={() => navigate('/experience/'+d._id)}>{d.experienceName}</a></td>
							</tr>
						);
					})}
				</tbody>
			</Table>

		</Container>
	);
};
