// React
import React, { useEffect, useState, useRef } from "react";
// Redux
/*import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'*/
import { io, Socket } from "socket.io-client";
import axios from "axios";
// Components
import { Header } from "./../Partials/Header";
import { Footer } from "./../Partials/Footer";
// Style
import { Container, Button, Card, Row, Col, Table } from "react-bootstrap";
// Css
import "./sessions.css";
import { Route, useParams } from "react-router-dom";
import moment from "moment";
import { getDataSimple } from "../../actions";

export const API_URL = process.env.REACT_APP_API_URL;

export const Session = () => {
	let { id } = useParams();
	const [session, setSession] = useState<any>([]);
	const [mail, setMail] = useState<any>([]);
	const [events, setEvents] = useState<any>([]);
	const [experiences, setExperiences] = useState<any>([]);

	useEffect(() => {

		let urlSessions = "/session/" + id;

		console.log(urlSessions);

		getDataSimple("getSessionError", true, urlSessions).then(response => {
			console.log("response=", response);
			setSession(response.response.data.session);

			if (response.response.data.session) {
				setMail(response.response.data.mail);

				let receipt = document.getElementById("receiptHtml");

				if (receipt && response.response.data.ticket) {
					receipt.innerHTML = response.response.data.ticket.receiptHtml;
					receipt.style.display = "flex";
				}
			}
		}).catch((e: any) => {console.log("response data errror = ", e) });

		console.log("---------------------")
		// axios({
		// 	url: urlSessions,
		// 	method: "GET",
		// 	headers: { Authorization: localStorage['userAdminToken'] },
			
		// }).then((response) => {
		// 	console.log("response=", response);
		// 	setSession(response.data.session);

		// 	if (response.data.session) {
		// 		setMail(response.data.mail);

		// 		let receipt = document.getElementById("receiptHtml");

		// 		if (receipt && response.data.ticket) {
		// 			receipt.innerHTML = response.data.ticket.receiptHtml;
		// 			receipt.style.display = "flex";
		// 		}
		// 	}
		// });
	}, []);

	useEffect(() => {
		if (session) {
			let urlEvents = API_URL + "/event/all/" + id;

			console.log(urlEvents);

			axios({	
				url: urlEvents,
				method: "GET",
				headers: { Authorization: localStorage['userAdminToken'] },
			})
				.then((response) => {
					console.log("eventsRetreived=", response);
					setEvents(response.data.events);
				})
				.catch((e: any) => {});

			let urlExperience = API_URL + "/tscapture/experiences";

			console.log(urlExperience);

			axios({
				url: urlExperience,
				method: "GET",
				headers: { Authorization: localStorage['userAdminToken'] },
			})
				.then((response) => {
					console.log("experiencesRetrieved=", response);
					setExperiences(response.data.experiences);
				})
				.catch((e: any) => {});
		}
	}, [session]);

	return (
		<Container className='main'>
			<h3>{"Session " + session._id}</h3>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "baseline",
				}}
			>
				<p style={{ marginRight: "8px" }}>{mail?.to || ""}</p>
				<input
					type='button'
					onClick={() => {
						if (
							window.confirm(
								"Êtes-vous sûr d'envoyer le mail une nouvelle fois ?"
							)
						) {
							let urlExperience = API_URL + "/session/resend/" + session._id;

							console.log("session=", session);

							console.log(urlExperience);

							/*axios({
							url: urlExperience,
							method: "GET",
						})
							.then((response) => {
								console.log("resendMail=", response);
							})
							.catch((e: any) => {});*/
						}
					}}
					value='Send mail again'
				/>
			</div>
			<br />
			<div
				id='receiptHtml'
				style={{
					border: "1px solid black",
					padding: "8px",
					display: "none",
					flexDirection: "column",
					marginBottom: "1em"
				}}
			></div>
			<h4>{"Takes :"}</h4>
			<Row style={{ width: "100%", margin: "0", marginLeft: "0px" }}>
				{session &&
					session.takes &&
					session.takes.map((t: any, index: number) => {
						return (
							<Col
								key={"take" + t}
								xs={12}
								lg={4}
								style={{
									marginTop: "12px",
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "center",
									border: "1px solid grey",
								}}
							>
								<div
									style={{
										marginTop: "12px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										alignItems: "center",
									}}
								>
									{t}
								</div>
								<div
									style={{
										marginTop: "12px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										alignItems: "center",
									}}
								>
									<a
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "flex-start",
											alignItems: "center",
										}}
										href={API_URL + "/data/print/" + session._id + "/" + t + ""}
										target={"_blank"}
									>
										<img
											style={{ width: "80%" }}
											src={
												API_URL + "/data/print/" + session._id + "/" + t + ""
											}
										/>
									</a>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<a
										style={{
											margin: "12px 0 12px 0",
											display: "flex",
											flexDirection: "column",
											justifyContent: "flex-start",
											alignItems: "center",
										}}
										href={API_URL + "/data/video/" + session._id + "/" + t + ""}
										target={"_blank"}
									>
										Out
									</a>
								</div>
								<div
									style={{
										width: "100%",
										marginTop: "12px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										alignItems: "center",
										borderTop: "1px solid black",
									}}
								>
									{"Sharing link photo :"}
								</div>
								<div
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{experiences &&
										experiences.find(
											(e: any) => e.experienceName === session.idExperience
										) && (
											<a
												style={{
													margin: "0 0 0 0",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
													textAlign: "center",
												}}
												href={
													experiences.find(
														(e: any) =>
															e.experienceName === session.idExperience
													).urlFront +
													"/p/" +
													session._id
												}
												target={"_blank"}
											>
												{experiences.find(
													(e: any) => e.experienceName === session.idExperience
												).urlFront +
													"/p/" +
													session._id}
											</a>
										)}
								</div>
								<div
									style={{
										width: "100%",
										marginTop: "12px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										alignItems: "center",
									}}
								>
									{"Sharing link video :"}
								</div>
								<div
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									{experiences &&
										experiences.find(
											(e: any) => e.experienceName === session.idExperience
										) && (
											<a
												style={{
													margin: "0 0 0 0",
													display: "flex",
													flexDirection: "column",
													justifyContent: "center",
													alignItems: "center",
													textAlign: "center",
												}}
												href={
													experiences.find(
														(e: any) =>
															e.experienceName === session.idExperience
													).urlFront +
													"/v/" +
													session._id
												}
												target={"_blank"}
											>
												{experiences.find(
													(e: any) => e.experienceName === session.idExperience
												).urlFront +
													"/v/" +
													session._id}
											</a>
										)}
								</div>
							</Col>
						);
					})}
			</Row>
			<br />
			<h4>{"Events :"}</h4>
			{events &&
				events.map((event: any, index: number) => {
					return (
						<Row key={"evt" + index} style={{ width: "100%" }}>
							<Col xs={2}>
								{moment(event.createdAt).format("DD/MM/WW HH:mm:ss:SSS")}
							</Col>
							<Col xs={2}>{event.type}</Col>
							<Col xs={8}>{JSON.stringify(event.data)}</Col>
						</Row>
					);
				})}
		</Container>
	);
};
