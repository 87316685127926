// React
import React from "react";
// Redux
//import { Dispatch } from 'redux';
import { useSelector } from "react-redux";
// Components
import { Container } from "react-bootstrap";

export const Main = () => {
	const { connected } = useSelector((state: any) => state.user);
	//const dispatch: Dispatch<any> = useDispatch();

	return (
		<React.Fragment>
			{connected ? (
				<Container className={"main"}>Hello connected</Container>
			) : (
				<Container className={"main"}>Hello</Container>
			)}
		</React.Fragment>
	);
};
