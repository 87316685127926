import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface PopupProps {
  title: string;
  body: string;
  primaryLabel: string;
  secondaryLabel: string;
  onPrimaryClick: () => void;
  onSecondaryClick: () => void;
  show: boolean;
  handleClose: () => void;
}

export const Popup = ({ title, body, primaryLabel, secondaryLabel, onPrimaryClick, onSecondaryClick, show, handleClose }: PopupProps) => {
    return (
        <>
            <Modal show={show} onHide={handleClose} style={{ marginTop: '50px' }}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={onSecondaryClick}>
                        {secondaryLabel}
                    </Button>
                    <Button variant="primary" onClick={onPrimaryClick}>
                        {primaryLabel}
                    </Button>

                </Modal.Footer>
            </Modal>
            <div className={`modal-backdrop fade ${show ? 'show' : ''}`} />
        </>
    );
    }
export default Popup;
