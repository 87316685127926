import { createAction } from '@reduxjs/toolkit';
import axios from 'axios';

import { getData } from './index';

import { history } from '../history';

// types
import { POPUP_DISPLAY } from './types';

export const setPopupDisplay = createAction<boolean>(POPUP_DISPLAY);