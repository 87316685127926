// React
import React, { useEffect, useState, useRef } from "react";
// Redux
/*import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'*/
import { io, Socket } from "socket.io-client";
// Components
import { Header } from "./../Partials/Header";
import { Footer } from "./../Partials/Footer";
import TouchscreenComponent from "../Touchscreen/Touchscreen";
// Style
import { Container, Button, Card, Row, Col } from "react-bootstrap";
// Css
import "./manager.css";
import Popup from "../Partials/Popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faHandPointUp } from "@fortawesome/free-solid-svg-icons";

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const Manager = () => {
	const [socket, setSocket] = useState<Socket>();

	/*const { connected } = useSelector((state: any) => state.user);
	const dispatch: Dispatch<any> = useDispatch();*/

	// userDatas
	const [userDatas, setUserDatas] = useState<Array<any> | []>([]);
	const userDatasRef = useRef(userDatas);

	useEffect(() => {
		userDatasRef.current = [...userDatas];
	}, [userDatas]);

	/*useEffect(() => {
		if (natverkClient) {
			natverkClient.onSocketClose.on(() => {
				console.log('socket disconnected');
			});

			natverkClient.onUserConnect.on(() => {
				console.log('connect');
				refresh(natverkClient);
			});

			natverkClient.onUserDisconnect.on(() => {
				console.log('disconnect');
				refresh(natverkClient);
			})

			natverkClient.onUserChangeNick.on(() => {
				console.log('change nick');
				refresh(natverkClient);
			})

            natverkClient.onUserChangeData.on(() => {
				console.log('change data');
				refresh(natverkClient);
			})

			natverkClient.onSocketOpen.on(() => {
				natverkClient.setMeInfos({
					nick:"admin"
				})
			})
		}
	}, [socketTemp])*/

	useEffect(() => {
		/*let natverkClient = new NatverkClient({
			url: (process.env.REACT_APP_SERVER_URL as string),
			port: 20070,
            log: true,
			logPrefix: "manager",
			autoconnect: true,
			authSecret: localStorage["userAdminToken"]
		});

		setNatverkClient(natverkClient);*/

		let socketUrl = SERVER_URL as string;

		let socketTemp = io(socketUrl, {
			reconnection: true,
			query: {
				id: "test",
				group: "tsadmin",
			},
		});

		socketTemp.on("clients", (data) => {
			console.log("socket received", data);

			let ud = [];
			for (let d of data) {
				ud.push(d);
			}

			setUserDatas(ud);
		});

		socketTemp.on("connect", () => {
			console.log("Connect to manager with id " + socketTemp.id);
		});

		socketTemp.on("disconnect", () => {
			console.log("Disconnect from manager with id " + socketTemp.id);
		});

		setSocket(socketTemp);

		return function cleanup() {
			socketTemp.disconnect();
		};
	}, []);

	/*let refresh = (natverkClient:NatverkClient) => {
		let users:any[] = [];

        //console.log("users", natverkClient.users);

		natverkClient.users.forEach((value) => {
			users.push(value)

            //console.log("users", value.data)

		})

		setUserDatas(users)
	}*/

	let restart = (id: string) => {
		if (window.confirm("Do you really want to restart this device?")) {
			console.log("restart " + id);
			socket?.emit("onRestart", id);
		}
	};

	return (
		// <Popup></Popup>
		<Container className='main'>
			<div className='container-fluid' style={{ width: "100%" }}>
				<h1 className='h3 mb-2 text-gray-800'>Manager :</h1>
				<br />
				{/* <p className="mb-4">DataTables is a third party plugin that is used to generate the demo table below.
	For more information about DataTables, please visit the <a target="_blank"
		href="https://datatables.net">official DataTables documentation</a>.</p> */}

				<div className='card shadow mb-4'>
					<div className='card-header py-3'>
						<h6 className='m-0 font-weight-bold text-primary'>TSC_devices</h6>
					</div>
					<div className='card-body'>
						<div className='table-responsive'>
							<table className='table table-bordered' id='dataTable' width='100%' cellSpacing='0'>
								<thead>
									<tr>
										<th>Name</th>
										<th>Tags</th>
										<th>Printers</th>
										<th>Page</th>
										<th>Actions</th>
										<th>Statut</th>
									</tr>
								</thead>
								<tbody>
									{userDatas
										.filter((ud) => ud.socketGroup === "tscapture")
										.map((s: any) => {
											console.log("s=", s);
											return (
												<tr key={s.clientId}>
													<td>
														{(s.clientName || "id: " + s.clientId) +
															(s.verified ? "" : " (not registered in DB)")}
													</td>
													<td>
														<div
															style={{
																width: "100%",
																height: "100%",
																display: "flex",
																flexDirection: "column",
																justifyContent: "flex-start",
																alignItems: "flex-start",
															}}
														>
															{s.options?.map((o: any) => {
																return (
																	<a
																		key={"option" + s.clientId + "_" + o.name}
																		href=''
																		className={
																			"btn " +
																			(o.value === true
																				? "btn-success"
																				: o.value === false
																				? "btn-danger"
																				: "btn-secondary") +
																			" btn-icon-split btn-sm"
																		}
																		style={{ marginRight: "4px" }}
																		onClick={() =>
																			window.alert(
																				"For now, no action are implemented."
																			)
																		}
																	>
																		<span className='text'>{o.name}</span>
																	</a>
																);
															})}
														</div>
													</td>
													<td>
														<div
															style={{
																width: "100%",
																height: "100%",
																display: "flex",
																flexDirection: "column",
																justifyContent: "flex-start",
																alignItems: "flex-start",
															}}
														>
															{s.printers?.map((p: any, index: number) => {
																return (
																	<div key={"printers" + s.clientId + "_" + index}>
																		{p.name + " : " + p.printerCount}
																	</div>
																);
															})}
														</div>
													</td>
													<td>
														{(s.page ? s.page : "?") + (s.subpage ? " / " + s.subpage : "")}
													</td>
													<td style={{ width: "20%" }}>
														<a
															href=''
															className='btn btn-primary btn-icon-split btn-sm'
															style={{ marginRight: "4px" }}
															onClick={() => restart(s.clientId)}
														>
															<span
																className='icon text-white-50'
																style={{ marginRight: "4px" }}
															>
																<FontAwesomeIcon icon={faPowerOff} />
															</span>
															<span className='text'>Restart</span>
														</a>
														<a
															className='btn btn-primary btn-icon-split btn-sm'
															style={{ marginRight: "4px" }}
														>
															<span
																className='icon text-white-50'
																style={{ marginRight: "4px" }}
															>
																<FontAwesomeIcon icon={faHandPointUp} />
															</span>
															<TouchscreenComponent
																clientId={s.clientId}
																socket={socket}
															/>
														</a>
														{/* <span onClick={() => restart(s.clientId)}>Restart</span> */}
													</td>
													<td style={{ width: "5%" }}>
														<div
															style={{
																aspectRatio: "1/1",
																backgroundColor: s.state === 1 ? "green" : "red",
																height: "16px",
																borderRadius: "50%",
															}}
														></div>
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};
